@import url('https://fonts.googleapis.com/css?family=Muli:400,700&display=swap');

* {
  outline: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Muli', sans-serif !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 0.875rem !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:active,
a:focus,
a:hover {
  text-decoration: none !important;
}
/* .css-26l3qy-menu {
  background-color: white !important;
  opacity: 1 !important;
} */
